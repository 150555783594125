<template>
  <div>
    <b-modal
      @hidden="resetModal"
      ref="modal-payout"
      centered
      hide-footer
      size="lg"
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
      <template #modal-title>
        <h5 class="text-info mb-0 text-dark">
          <i class="bi bi-receipt text-primary me-1"></i> Create Payout
        </h5>
      </template>
      <div class="row align-items-center">
        <div class="col-12">
          <p class="font-size-14 text-muted mb-2">Recipient Info</p>
        </div>
        <div class="col-12">
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Merchant</label>
            <div class="col-lg-9 col-12">
              <multiselect
                v-model="selectedmerchant"
                :options="merchant"
                label="account_name"
                class=""
                placeholder="Select Merchant"
                :close-on-select="true"
                :allow-empty="false"
                deselect-label=""
                :show-on-select="true"
                :disabled="disabled"
                @input="changeMerchant()"
                :class="{
                  'is-invalid': modalSubmit && $v.formData.merchantId.$error,
                }"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.account_db_code !== 'all'">
                    <span class="option__title">
                      <span
                        v-if="props.option.status == 'inactive'"
                        class="text-danger me-1 text-uppercase"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.status == 'active'"
                        class="text-success me-1 text-uppercase"
                        >&#9679;
                      </span>
                      #{{ props.option.id }}
                      {{ props.option.account_name }}</span
                    >
                  </span>
                  <span v-else>
                    {{ props.option.account_name }}
                    <span v-if="merchant.length > 1"
                      >({{ merchant.length - 1 }})</span
                    ><span v-else>(0)</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.account_db_code !== 'all'">
                    <span class="option__title">
                      <span
                        v-if="props.option.status == 'inactive'"
                        class="text-danger me-1 text-uppercase"
                        >&#9679;</span
                      >
                      <span
                        v-else-if="props.option.status == 'active'"
                        class="text-success me-1 text-uppercase"
                        >&#9679;
                      </span>
                      #{{ props.option.id }}
                      {{ props.option.account_name }}</span
                    >
                  </span>
                  <span v-else>
                    {{ props.option.account_name }}
                    <span v-if="merchant.length > 1"
                      >({{ merchant.length - 1 }})</span
                    ><span v-else>(0)</span>
                  </span>
                </template>
                <span slot="noResult">Oops! No merchant found. </span>
              </multiselect>
              <div
                v-if="modalSubmit && $v.formData.merchantId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.merchantId.required"
                  >Merchant is required.</span
                >
                <span v-else-if="!$v.formData.merchantId.equalDefault"
                  >Merchant Id is required.</span
                >
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label" v-if="baseCurrency=='HKD'"
              >Balance</label
            >
            <label class="col-lg-3 col-12 col-form-label" v-else
              >Available Balance</label
            >
            <div class="col-lg-9 col-12">
              <multiselect
                ref="select"
                v-model="selectedmerchantContract"
                :options="merchantContract"
                label="account_name"
                class="mb-3"
                placeholder="Select"
                :close-on-select="true"
                :allow-empty="false"
                deselect-label=""
                :show-on-select="true"
                :disabled="disabled"
                @open="isOpen = true"
                @close="isOpen = false"
                @input="changeContract()"
                :class="{
                  'is-invalid': modalSubmit && $v.formData.contractId.$error,
                }"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span v-if="props.option.contract_id !== '-1'">
                    <span
                      v-if="props.option.contract_status == 'unavailable'"
                      class="text-warning me-1"
                      >&#9679;</span
                    >
                    <span
                      v-else-if="props.option.contract_status == 'available'"
                      class="text-success me-1"
                      >&#9679;</span
                    >
                    <span class="option__title">
                      {{ props.option.contract_id }}:
                      {{ props.option.contract_name }} -
                      {{ props.option.contract_currency }}
                      <span v-if="props.option.contract_balance">
                        {{
                          convertCurrencyFormat(
                            props.option.contract_balance,
                            true
                          )
                        }}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }} </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span v-if="props.option.contract_id !== '-1'">
                    <span
                      v-if="props.option.contract_status == 'unavailable'"
                      class="text-warning me-1"
                      >&#9679;</span
                    >
                    <span
                      v-else-if="props.option.contract_status == 'available'"
                      class="text-success me-1"
                      >&#9679;</span
                    >
                    <span class="option__title">
                      {{ props.option.contract_id }}:
                      {{ props.option.contract_name }} -
                      {{ props.option.contract_currency }}
                      <span v-if="props.option.contract_balance">
                        {{
                          convertCurrencyFormat(
                            props.option.contract_balance,
                            true
                          )
                        }}
                      </span>
                      <span v-else>0.00</span>
                    </span>
                  </span>
                  <span v-else>{{ props.option.contract_name }}</span>
                </template>
                <span slot="noResult">Oops! No Contract found. </span>
              </multiselect>
              <div
                v-if="modalSubmit && $v.formData.contractId.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.contractId.required"
                  >Contract is required.</span
                >
                <span v-else-if="!$v.formData.contractId.equalDefault"
                  >Contract Id is required.</span
                >
              </div>
              <div
                class="mt-2"
                v-if="selectedmerchantContract.contract_id !== -1"
              >
                <h5
                  class="font-size-14 bg-soft-info text-info p-2 fw-medium mb-0 d-inline-block rounded"
                >
                  <i class="uil uil-wallet"></i>
                  {{ selectedmerchantContract.contract_currency }}
                  <span v-if="selectedmerchantContract.contract_balance">
                    {{
                      convertCurrencyFormat(
                        selectedmerchantContract.contract_balance,
                        true
                      )
                    }}
                  </span>
                  <span v-else>0.00</span>
                </h5>
              </div>

              
            </div>
          </div>
          <div class="mb-3 row" v-if="baseCurrency=='HKD'">
            <label class="col-lg-3 col-12 col-form-label mb-0">Frozen Amount </label>
            <div class="col-lg-9 col-12">
              <h5
                  class="font-size-14 bg-soft-danger text-danger p-2 fw-medium mb-0 d-inline-block rounded"
                >
                  <i class="bi bi-snow2 font-size-13"></i>
                  {{ selectedmerchantContract.contract_currency }}
                  <span v-if="selectedmerchantContract.contract_frozen">
                    {{
                      convertCurrencyFormat(
                        selectedmerchantContract.contract_frozen,
                        true
                      )
                    }}
                  </span>
                  <span v-else>0.00</span>
                </h5>
            </div>
          </div>
          <div class="mb-3 row" v-if="baseCurrency=='HKD'">
            <label class="col-lg-3 col-12 col-form-label mb-0">Available Amount </label>
            <div class="col-lg-9 col-12">
              <h5
                  class="font-size-14  p-2 fw-medium mb-0 d-inline-block rounded"
                  :class="{'bg-soft-success text-success':selectedmerchantContract.contract_balance_after_frozen>0, 
                    'bg-soft-secondary text-muted':selectedmerchantContract.contract_balance_after_frozen<0

                  }"
                >
               
                  <i class="bi bi-check-circle font-size-13 me-1" v-if="selectedmerchantContract.contract_balance_after_frozen>0"></i>
                  <i class="bi bi-x-circle font-size-13 me-1" v-else></i>
                  {{ selectedmerchantContract.contract_currency }}
                  <span v-if="selectedmerchantContract.contract_balance_after_frozen">
                    {{
                      convertCurrencyFormat(
                        selectedmerchantContract.contract_balance_after_frozen,
                        true
                      )
                    }}
                  </span>
                  <span v-else>0.00</span>
                </h5>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Payout Amount <span class="text-muted d-block fw-normal font-size-13" v-if="currencyMin!=='0'">(Min. {{selectedmerchantContract.contract_currency}} <span v-if="currencyMin">{{ convertCurrencyFormat(currencyMin,false) }}</span>)</span></label>
            <div class="col-lg-9 col-12">
              <div class="input-group">
                <span class="input-group-text text-white border-info bg-info" id="basic-addon1">{{
                  selectedmerchantContract.contract_currency || '-'
                }}</span>
                <input
                  :disabled="
                    !(selectedmerchantContract.contract_balance_after_frozen > 0) || loading
                  "
                  v-model.trim="formData.payoutAmount"
                  type="text"
                  inputmode="decimal"
                  @input="
                    inputNumberOnly($event, 'payoutAmount'),
                      $v.formData.$touch()
                  "
                  class="form-control"
                  placeholder="Payout Amount"
                  name="payoutAmount"
                  :class="{
                    'is-invalid':
                      modalSubmit && $v.formData.payoutAmount.$error,
                  }"
                />
                <!--  @blur="changeCurrency()" -->
                <div
                  v-if="modalSubmit && $v.formData.payoutAmount.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.payoutAmount.required"
                    >Payout Amount is required.</span
                  >
                 
                  <span v-if="!$v.formData.payoutAmount.lessThanBalance">Please make sure that the payout amount, including charges, remains within the limit of {{ selectedmerchantContract.contract_currency }} {{ calculateBalance() }} .</span>
                  <span v-if="!$v.formData.payoutAmount.minPayout"> Must be greater than the minimum payout amount.</span>
                </div>
                <!-- <div v-if="modalSubmit && $v.formData.payoutAmountAfterCovertRate.$error" class="invalid-feedback">
                  <span v-if="!$v.formData.payoutAmountAfterCovertRate.minSettlement">Must be greater than the minimum settlement amount.</span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label"
              >Beneficiary Receives Amount</label
            >
            <div class="col-lg-9 col-12">
              <div class="bg-info p-3 px-3 rounded">
                <table class="table mb-0 text-white">
                  <tr>
                    <td>Amount</td>
                    <td class="text-end">
                      <span
                        class="p-0 text-custom fw-medium"
                        v-if="selectedmerchantContract.contract_balance_after_frozen"
                        >{{ selectedmerchantContract.contract_currency }}
                        {{
                          convertCurrencyFormat(
                            this.formData.payoutAmount || 0,
                            true
                          )
                        }}
                      </span>
                      <span v-else class="p-0">-</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label"
              >Contract Balance After Payout
            </label>
            <div class="col-lg-9 col-12">
              <div class="bg-info p-3 px-3 rounded">
                <table class="table mb-0 text-white">
                  <tr>
                    <td>Currenct Balance</td>
                    <td class="text-end">
                      <span
                        class="p-0"
                        v-if="selectedmerchantContract.contract_balance_after_frozen"
                        >{{ selectedmerchantContract.contract_currency }}
                        {{
                          convertCurrencyFormat(
                            selectedmerchantContract.contract_balance_after_frozen,
                            true
                          )
                        }}
                      </span>
                      <span class="p-0" v-else
                        >{{
                          selectedmerchantContract.contract_currency
                        }}
                        0.00</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="align-top">Payout Amount</td>
                    <td class="text-end align-top">
                      <span
                        class="p-0"
                        v-if="selectedmerchantContract.contract_balance_after_frozen"
                        >- {{ selectedmerchantContract.contract_currency }}
                        {{
                          convertCurrencyFormat(
                            formData.payoutAmount || 0,
                            true
                          )
                        }}</span
                      >
                      <span v-else class="p-0">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="border-bottom border-primary pb-2 align-top">
                      Payout Rate and Extra Charges
                      <span
                        v-if="selectedmerchantContract.payout_rate"
                        class="d-block p-0 font-size-13 text-white text-opacity-75"
                        >({{ selectedmerchantContract.payout_rate }}% +
                        {{ selectedmerchantContract.contract_currency }}
                        {{
                          convertCurrencyFormat(
                            selectedmerchantContract.payout_extra_charges,
                            true
                          )
                        }})</span
                      >
                    </td>
                    <td
                      class="text-end border-bottom border-primary pb-2 align-top"
                    >
                      <span class="p-0" v-if="formData.payoutAmount"
                        >- {{ selectedmerchantContract.contract_currency }}
                        {{ convertCurrencyFormat(calculateCharge(), true) }}
                      </span>
                      <span v-else class="p-0">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="pt-2">Contract Balance</td>
                    <td class="text-end pt-2">
                      <span
                        class="p-0 fw-medium"
                        v-if="selectedmerchantContract.contract_balance_after_frozen"
                        :class="
                          parseFloat(calculateBalance()) >= 0
                            ? 'text-custom'
                            : 'text-custom-danger'
                        "
                      >
                        {{ selectedmerchantContract.contract_currency }}
                        {{ calculateBalance() }}
                      </span>
                      <span v-else class="p-0">-</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-lg-3 col-12 col-form-label">Currency</label>
            <div class="col-lg-9 col-12">
              <div class="bg-light p-3 px-3 rounded">
                {{ formData.amountToReceiveCurrency.displayName }}
              </div>
            </div>
          </div>
          <div>
            <div class="mb-3 row">
              <label
                class="col-lg-3 col-12 col-form-label"
                v-if="formData.amountToReceiveCurrency.shortName !== 'USDT'"
                >Receiving Bank</label
              >
              <label class="col-lg-3 col-12 col-form-label" v-else
                >Cryptocurrency</label
              >
              <div class="col-lg-9 col-12">
                <multiselect
                  v-if="banksList.length"
                  v-model="selectedBanks"
                  @input="updateBank"
                  :options="banksList"
                  label="name"
                  class=""
                  group-label="category"
                  group-values="list"
                  placeholder="Select Bank"
                  track-by="name"
                  :close-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  select-label=""
                  :show-on-select="true"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                  :class="{
                    'is-invalid':
                      modalSubmit && $v.formData.amountToReceiveCurrency.$error,
                  }"
                >
                  <!-- <template slot="singleLabel" slot-scope="props"><span v-if="props.option.list!==-1">
                        {{ props }}
                        <span class="option__title">{{ props.option.name }}</span></span></template><template slot="option" slot-scope="props"><span v-if="props.option.id!==-1"><span class="option__title">{{ props.option.name }}</span></span></template> -->
                  <span slot="noResult">Oops! No bank found. </span>
                </multiselect>
                <div
                  v-if="modalSubmit && $v.formData.bankName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.bankName.required"
                    >Receiving Bank is required.</span
                  >
                  <span v-else-if="!$v.formData.bankName.equalDefault"
                    >Receiving Bank is required.</span
                  >
                </div>
                <div>
                  <input
                    v-if="!(banksList.length > 0)"
                    v-model.trim="formData.bankName"
                    type="tel"
                    class="form-control"
                    placeholder="Receiving Bank"
                    name="bankName"
                    :disabled="
                      banksList.length > 0 ||
                      formData.amountToReceiveCurrency == '' ||
                      formData.amountToReceiveCurrency.shortName == 'USDT'
                    "
                    :class="{
                      'is-invalid': modalSubmit && $v.formData.bankName.$error,
                    }"
                  />
                  <div
                    v-if="modalSubmit && $v.formData.bankName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.formData.bankName.required"
                      >Receiving Bank is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                class="col-lg-3 col-12 col-form-label"
                v-if="formData.amountToReceiveCurrency.shortName !== 'USDT'"
              >
                Beneficiary Account No.</label
              >
              <label class="col-lg-3 col-12 col-form-label" v-else
                >Wallet Address</label
              >
              <div
                class="col-lg-9 col-12"
                v-if="formData.amountToReceiveCurrency.shortName !== 'USDT'"
              >
                <input
                  v-model.trim="formData.accountNumber"
                  type="tel"
                  class="form-control"
                  placeholder="Account Number"
                  name="accountNumber"
                  :class="{
                    'is-invalid':
                      modalSubmit && $v.formData.accountNumber.$error,
                  }"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
                <div
                  v-if="modalSubmit && $v.formData.accountNumber.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.accountNumber.required"
                    >Beneficiary Account No. is required.</span
                  >
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input
                  v-model.trim="formData.accountNumber"
                  type="text"
                  class="form-control"
                  placeholder="Wallet Address"
                  name="accountNumber"
                  :class="{
                    'is-invalid':
                      modalSubmit && $v.formData.accountNumber.$error,
                  }"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
                <div
                  v-if="modalSubmit && $v.formData.accountNumber.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.accountNumber.required"
                    >Receiver's Address is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <label
                class="col-lg-3 col-12 col-form-label"
                v-if="formData.amountToReceiveCurrency.shortName !== 'USDT'"
                >Beneficiary Name</label
              >
              <label class="col-lg-3 col-12 col-form-label" v-else
                >Network</label
              >
              <div
                class="col-lg-9 col-12"
                v-if="formData.amountToReceiveCurrency.shortName !== 'USDT'"
              >
                <input
                  v-model.trim="formData.accountName"
                  type="text"
                  class="form-control"
                  placeholder="Account Name"
                  name="accountName"
                  :class="{
                    'is-invalid': modalSubmit && $v.formData.accountName.$error,
                  }"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
                <div
                  v-if="modalSubmit && $v.formData.accountName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.accountName.required"
                    >Beneficiary Name is required.</span
                  >
                </div>
              </div>
              <div class="col-lg-9 col-12" v-else>
                <input
                  v-model.trim="formData.accountName"
                  type="text"
                  class="form-control"
                  placeholder="Network"
                  name="accountName"
                  :class="{
                    'is-invalid': modalSubmit && $v.formData.accountName.$error,
                  }"
                  :disabled="
                    disabled ||
                    formData.amountToReceiveCurrency == '' ||
                    formData.amountToReceiveCurrency.shortName == 'USDT'
                  "
                />
                <div
                  v-if="modalSubmit && $v.formData.accountName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.accountName.required"
                    >Network is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField1.enabled">
            
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField1.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField1.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField1.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField2.enabled">
            
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField2.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField2.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField2.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row" v-if="formData.extraField3.enabled">
              <label
                class="col-lg-3 col-12 col-form-label"
                >{{formData.extraField3.name}}</label
              >
              <div
                class="col-lg-9 col-12"
              >
                <input
                  v-model.trim="formData.extraField3.value"
                  type="text"
                  class="form-control"
                  :placeholder="formData.extraField3.name"
                  name="accountName"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label"
                >Payout Description
                <span class="text-muted d-block fw-normal font-size-13">(Optional)</span>
              </label>
              <div class="col-lg-9 col-12">
                <textarea
                  rows="4"
                  v-model.trim="formData.transactionDescription"
                  type="text"
                  class="form-control"
                  placeholder="Payout Description"
                  name="transactionDescription"
                  :disabled="disabled || formData.amountToReceiveCurrency == ''"
                ></textarea>
                <!-- <div
                  v-if="modalSubmit && $v.formData.transactionDescription.$error"
                  class="invalid-feedback"
                ><span v-if="!$v.formData.transactionDescription.required"
                    >Payout Description is required.</span
                  ></div> -->
              </div>
            </div>
            <div class="mb-3 row">
              <label class="col-lg-3 col-12 col-form-label">Callback Url <span class="text-muted d-block fw-normal font-size-13">(Optional)</span>
              </label>
              <div class="col-lg-9 col-12">
               
                  <input v-model.trim="formData.callbackUrl" type="text" class="form-control" placeholder="Callback Url" name="callbackUrl" :disabled="disabled || formData.amountToReceiveCurrency == ''" />
              </div>
            </div>
            <hr />
            <div class="d-grid gap-2 d-md-block text-end">
              <button
                type="button"
                class="order-1 btn fw-medium btn-secondary me-md-2"
                @click="$refs['modal-payout'].hide()"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn fw-medium btn-info"
                @click="addpayoutAmount"
                :disabled="modalLoading"
              >
                <span v-if="modalLoading"> Submitting...</span>
                <span v-else> Submit </span>
                <span
                  class="spinner-border spinner-border-sm ms-2"
                  v-if="modalLoading"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
  </div>
</template>
<script>
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import Common from "@/components/common";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
//const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
// const validWebsiteUrl = (value) => {
//   console.log(value);
//   const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
//   return regex.test(value);
// }
// const validUsername = (value) => {
//   const regex = /^[a-z0-9]+$/;
//   return regex.test(value);
// };
const equalDefault = (value) => {
  return value !== -1;
};
/**
 * Starter page
 */
const BankDefault = [];
const MerchantDefault = {
  id: "",
  account_db_code: "all",
  account_name: "All Merchants",
  status: "active",
  reseller_name: "",
  reseller_id: "",
  merchant_code: "",
};
const MerchantContractDefault = {
  status: "0",
  payin_rate: 0,
  contract_id: "-1",
  payout_rate: 0,
  total_payin: 0,
  ewallet_rate: 0,
  total_payout: 0,
  contract_name: "Select Contract",
  contract_total: 0,
  contract_status: "",
  settlement_rate: 0,
  contract_balance: 0,
  total_settlement: 0,
  contract_currency: "",
  payin_extra_charges: 0,
  payout_extra_charges: 0,
  ewallet_extra_charges: 0,
  settlement_extra_charges: 0,
  contract_list_length_total: 0,
  merchant_Id: "",
  account_name: "",
  merchant_status: "",
  reseller_id: "",
  reseller_name: "",
  reseller_code: "",
  reseller_level: 0,
};
export default {
  components: {
    Multiselect,
    Common,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseCurrency: appConfig.baseCurrency,
      currencyMin: 0,
      currencyMax: 0,
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      selectedmerchant: MerchantDefault,
      merchant: [MerchantDefault],
      selectedmerchantContract: MerchantContractDefault,
      merchantContract: [MerchantContractDefault],
      selectedBanks: [],
      banksList: [],
      currencyList: [],
      minPayout:[],
      currencyChargePercentage: 0,
      loading: false,
      disabled: false,
      modalSubmit: false,
      modalLoading: false,
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      returnData: [],
      formData: {
        merchantId: "",
        contractId: "",
        amountToReceiveCurrency: "",
        bankName: "",
        accountNumber: "",
        accountName: "",
        transactionDescription: "",
        payoutAmount: "",
        callbackUrl: "",
        extraField1: {
          name: "",
          value: "",
          enabled: false,
        },
        extraField2: {
          name: "",
          value: "",
          enabled: false,
        },
        extraField3: {
          name: "",
          value: "",
          enabled: false,
        }
      },
    };
  },
  validations: {
    formData: {
      merchantId: {
        required,
        equalDefault,
      },
      contractId: {
        required,
        equalDefault,
      },
      bankName: {
        required,
        equalDefault,
      },
      amountToReceiveCurrency: {
        required,
      },
      accountNumber: {
        required,
      },
      accountName: {
        required,
      },
      payoutAmount: {
        required,
        lessThanBalance(value) {
          return value <= parseFloat(this.currencyMax);
        },
        minPayout(value) {
            return (value >= parseFloat(this.currencyMin));
        },
      },
      // payoutAmountAfterCovertRate: {
      //   required,
      //   minSettlement(value) {
      //     return (value >= parseFloat(this.currencyMin));
      //   },
      // },
      // password: {
      //   required,
      //   minLength: minLength(8),
      // },
    },
  },
  middleware: "authentication",
  async mounted() {
    //   this.title = PageTitle
    //   this.items[1].text = PageTitle
    //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    //   // this.$refs.main.changeVerticalTopBar("bill",true)
    //   // this.$refs.main.setShowFooterCert(false)
    //   // this.$refs.main.setPageTitle('title')
    //   console.log( this.$refs.commonFunc.getPhone())
    //   this.accessToken = this.$refs.commonFunc.getToken()
    //   this.accessUsername = this.$refs.commonFunc.getUsername()
    //   await this.getData();
  },
  created() {},
  methods: {
    updateBank() {
      this.formData.bankName = this.selectedBanks.name
    },
    calculateCharge() {
      var amount = this.formData.payoutAmount || 0;
      var charges = 0.0;
      if (this.formData.payoutAmount) {
        console.log(this.selectedmerchantContract.payout_rate);
        charges =
          parseFloat(amount) *
            parseFloat(this.selectedmerchantContract.payout_rate / 100) +
          parseFloat(this.selectedmerchantContract.payout_extra_charges);
      }
      this.currencyMax = (this.selectedmerchantContract.contract_balance_after_frozen - charges);
      return charges;
    },
    calculateTotal() {
      var amount = this.formData.payoutAmount;
      var charges = this.calculateCharge();
      var total = 0.0;
      total = amount - charges;
      console.log(amount, charges, total);
      total = this.convertCurrencyFormat(total, true);
      return total;
    },
    calculateBalance() {
      var amount = this.formData.payoutAmount;
      var charges = this.calculateCharge();
      var balance = this.selectedmerchantContract.contract_balance_after_frozen;
      balance = balance - amount - charges;
      console.log(amount, charges, balance);
      balance = this.convertCurrencyFormat(balance, true);
      return balance;
    },
    inputNumberOnly(event, parentModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, "");
      this.formData[parentModel] = numericValue;
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    showModal() {
      this.$refs["modal-payout"].show();
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.getMerchant();
      this.getCurrency();
    },
    changeMerchant() {
      // this.selectedBanks=BankDefault;
      this.formData.merchantId = this.selectedmerchant.account_db_code;
      this.formData.amountToReceiveCurrency = "";
      this.formData.payoutAmount = "";
      //this.formData.payoutAmountAfterCovertRate = '';
      this.formData.bankName = "";
      this.getMerchantContract();
    },
    changeContract() {
      this.currencyMax = this.selectedmerchantContract.contract_balance_after_frozen;
      this.formData.contractId = this.selectedmerchantContract.contract_id;
      this.formData.amountToReceiveCurrency = "";
      this.formData.payoutAmount = "";
      this.formData.callbackUrl= "";
      //this.formData.payoutAmountAfterCovertRate = ''
      this.formData.bankName = "";
      this.currencyList.forEach((element) => {
        if (
          element.shortName == this.selectedmerchantContract.contract_currency
        ) {
          this.formData.amountToReceiveCurrency = element;
        }
      });
      this.changeCurrency();
      if (this.minPayout.length){
        this.currencyMin = this.minPayout.find(element => element.currency==this.selectedmerchantContract.contract_currency).amount || 0
      }
    },
    changeCurrency() {
      this.selectedBanks = BankDefault;
      this.formData.bankName = "";
      this.formData.accountName = "";
      this.formData.accountNumber = "";
      this.formData.transactionDescription = "";
      this.formData.callbackUrl= "";
      this.formData.extraField1 = {
        name: "",
        value: "",
        enabled: false
      },
      this.formData.extraField2 = {
        name: "",
        value: "",
        enabled: false
      },
      this.formData.extraField3 = {
        name: "",
        value: "",
        enabled: false
      }
      //this.formData.password = "";
      //this.currencyMin = this.formData.amountToReceiveCurrency.minSettlementAmount
      this.formData.contractId = this.selectedmerchantContract.contract_id;
      if (this.formData.amountToReceiveCurrency.shortName == "USDT") {
        this.formData.bankName = "USDT";
        this.formData.accountName = this.formData.amountToReceiveCurrency.displayName;
      } else {
        this.formData.bankName = "";
        this.formData.accountName = "";
      }

      if (this.formData.amountToReceiveCurrency.shortName == "INR") {
        this.formData.extraField1= {
          name: "IFSC Code",
          value: "",
          enabled: true,
        }
      }
      if (this.formData.amountToReceiveCurrency.shortName == "INR_NATIVE") {
        this.formData.extraField1= {
          name: "IFSC Code",
          value: "",
          enabled: true,
        }
      }
      this.getBankList();
      // if (this.formData.amountToReceiveCurrency.contract_currency && this.selectedmerchantContract.contract_currency && this.formData.payoutAmount){
      //   if (this.formData.amountToReceiveCurrency.contract_currency !== this.selectedmerchantContract.contract_currency){
      //     this.convertCurrencyRates(this.formData.amountToReceiveCurrency.contract_currency,this.selectedmerchantContract.contract_currency,this.formData.payoutAmount)
      //   }else{
      //     //this.formData.payoutAmountAfterCovertRate = this.formData.payoutAmount
      //   }

      // }
      this.$v.formData.$touch();
    },
    convertCurrencyRates(theRateYouWant, current, amount) {
      //   axios({
      //     method: 'GET',
      //     url: 'https://api.api-ninjas.com/v1/convertcurrency?want=EUR&have=USD&amount=5000',
      //     headers: { 'X-Api-Key': 'gWaz9Mqz6dqWBUVfrmzQhQ==xIuu43VOC0JU3hZE'},
      //     contentType: 'application/json',
      //     success: function(result) {
      //         console.log(result);
      //     },
      //     error: function ajaxError(jqXHR) {
      //         console.error('Error: ', jqXHR.responseText);
      //     }
      // });
      //   return
      console.log("convertCurrencyRates");
      this.$Progress.start();
      //this.formData.payoutAmountAfterCovertRate = 0
      this.loading = true;
      this.disabled = true;
      axios({
        method: "get",
        url: `https://api.api-ninjas.com/v1/convertcurrency?want=${theRateYouWant}&have=${current}&amount=${amount}`,
        headers: { "X-Api-Key": "gWaz9Mqz6dqWBUVfrmzQhQ==xIuu43VOC0JU3hZE" },
        contentType: "application/json",
      })
        .then((response) => {
          var resData = response.data;
          console.log(resData);
          //this.formData.payoutAmountAfterCovertRate = resData.new_amount
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getBankList() {
      console.log("getBankList");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append(
        "currency",
        this.formData.amountToReceiveCurrency.shortName
      );
      var posturl = "";
      if (this.formData.amountToReceiveCurrency.shortName == "MYR") {
        posturl = "/api/bank_list_my.json";
      } else if (this.formData.amountToReceiveCurrency.shortName == "IDR") {
        posturl = "/api/bank_list_id.json";
      } else if (this.formData.amountToReceiveCurrency.shortName == "VND") {
        posturl = "/api/bank_list_vn.json";
      } else if (this.formData.amountToReceiveCurrency.shortName == "INR") {
        posturl = "/api/bank_list_in.json";
      }else if (this.formData.amountToReceiveCurrency.shortName == "INR_NATIVE") {
        posturl = "/api/bank_list_in.json";
      }else if (this.formData.amountToReceiveCurrency.shortName == "HKD") {
        posturl = "/api/bank_list_hk.json";
      }else {
        this.banksList = [];
        this.loading = false;
        this.disabled = false;
        this.$Progress.finish();
        return;
      }
      axios({
        method: "get",
        url: posturl,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          var resData = response.data;
          console.log(resData.result);
          if (resData.status == 200) {
            this.selectedBanks = BankDefault;
            this.banksList = [this.selectedBanks];
            const popular = [];
            const other = [];
            resData.result.forEach((element) => {
              if (element.category === "popular") {
                popular.push(element);
              } else {
                other.push(element);
              }
            });
            this.banksList = [
              {
                category: "Popular",
                list: popular,
              },
              {
                category: "Other",
                list: other,
              },
            ];
            this.selectedBanks = this.banksList[0].list[0];
            this.formData.bankName = this.selectedBanks.name;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getMerchant() {
      console.log("getMerchant");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      this.disable_date = true;
      this.merchant = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", "all");
      bodyFormData.append("searchMerchant", '');
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + "controller/admin/getAllMerchantList",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.merchant = [];
          var resData = response.data;
          if (resData.status == 200) {
            this.totalData = resData.total;
            var responseData = resData.data;

            responseData.forEach((element) => {
              this.merchant.push(element);
            });
            if (this.merchant.length > 0) {
              this.selectedmerchant = this.merchant[0];
              this.changeMerchant();
            }
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            this.loading = false;
            this.disabled = false;
          }
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getMerchantContract() {
      console.log("getMerchantContract");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "9999999");
      bodyFormData.append("reseller", "all");
      bodyFormData.append("merchant", this.selectedmerchant.account_db_code);
      axios({
        method: "post",
        url:
          appConfig.APIHostAdmin +
          "controller/admin/getContractListForMerchant",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.selectedmerchantContract = [];
            this.merchantContract = [];
            resData.data.forEach((element) => {
              this.merchantContract.push(element);
            });
            if (this.merchantContract.length > 0) {
              this.selectedmerchantContract = this.merchantContract[0];
              this.currencyMax = parseFloat(
                this.selectedmerchantContract.contract_balance_after_frozen
              );
            }
            this.changeContract();
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    getCurrency() {
      console.log("getCurrency");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      const uniqueParam = `nocache=${new Date().getTime()}`;
      axios({
          method: "get",
          url: '/api/currency_list.json?' + uniqueParam,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            this.currencyList = [];
            this.currencyChargePercentage = 0;
            this.currencyChargePercentage = resData.currencyChargePercentage;
            resData.localBankTransfer.forEach((element) => {
              this.currencyList.push(element);
            });
            // resData.internationalBankTransfer.forEach((element) => {
            //   this.currencyList.push(element);
            // });
            resData.cryptoTransfer.forEach((element) => {
              //temp close btc first
              if (element.shortName !== "BTC") {
                this.currencyList.push(element);
              }
            });
            this.minPayout = resData.minPayout;
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    resetModal() {
      this.selectedmerchant = MerchantDefault;
      this.selectedmerchantContract = MerchantDefault;
      this.selectedBanks = BankDefault;
      this.modalSubmit = false;
      this.modalLoading = false;
      this.formData= {
        merchantId: "",
        contractId: "",
        amountToReceiveCurrency: "",
        bankName: "",
        accountNumber: "",
        accountName: "",
        transactionDescription: "",
        payoutAmount: "",
        callbackUrl: "",
        extraField1: {
          name: "",
          value: "",
          enabled: false,
        },
        extraField2: {
          name: "",
          value: "",
          enabled: false,
        },
        extraField3: {
          name: "",
          value: "",
          enabled: false,
        }
      }
      this.minPayout=[]
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    addpayoutAmount() {
      this.modalSubmit = true;
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append('accessUsername', this.accessUsername);
        bodyFormData.append('accessToken', this.accessToken);
        bodyFormData.append('merchantId', this.formData.merchantId);
        bodyFormData.append('contractId', this.formData.contractId);
        bodyFormData.append('payoutAmount', this.formData.payoutAmount);
        bodyFormData.append('bankName', this.formData.bankName);
        bodyFormData.append('accountNumber', this.formData.accountNumber);
        bodyFormData.append('accountName', this.formData.accountName);
        bodyFormData.append('transactionDescription', this.formData.transactionDescription);
        bodyFormData.append('callbackUrl', this.formData.callbackUrl);
        bodyFormData.append('amountToReceiveCurrency',JSON.stringify(this.formData.amountToReceiveCurrency));
        bodyFormData.append('callbackUrl', this.formData.callbackUrl);
        bodyFormData.append('extraField1', JSON.stringify(this.formData.extraField1));
        bodyFormData.append('extraField2', JSON.stringify(this.formData.extraField2));
        bodyFormData.append('extraField3', JSON.stringify(this.formData.extraField3));
        console.log( this.formData.bankName)
     
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/addPayout',
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            this.returnData = [];
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData);
              Swal.fire({
                icon: "success",
                title: "Payout Request Created",
                html: `Your payout request has been successfully created.!`,
                confirmButtonColor: "#FA6541",
                confirmButtonText: this.$t("siteLang.Done"),
              }).then(() => {
                this.$refs["modal-payout"].hide();
                this.$emit('callParentFunction');
                if (this.$route.name!=="pending payouts"){
                  this.$router.push({
                    name: "pending payouts",
                  });
                }
              });
            } else if (resData.status == 440) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `${resData.message}.`,
                confirmButtonColor: "#222",
                confirmButtonText: this.$t("siteLang.Done"),
              });
            }
            this.modalLoading = false;
            this.modalSubmit = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.modalLoading = false;
            this.modalSubmit = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${error}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3 {
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>
