<template>
  <div>
    <b-modal
      @hidden="resetModal"
      ref="modal-extra-info"
      centered
      size="lg"
      hide-footer
      hide-header
      content-class="overflow-hidden"
      >
      <div class="row align-items-center">
        <div class="col-12 text-white border-bottom mb-3" >
          <h5 class="text-dark mb-3" v-if="extraInfo.info.hour_history"><i class="bi bi-bar-chart text-primary me-1"></i> {{extraInfo.title}}</h5>
          <h5 class="text-dark mb-3" v-else><i class="uil uil-comment-alt-notes text-primary"></i> {{extraInfo.title}}</h5>
        </div>
        <div class="col-12 text-muted overflow-auto">
          <div class="overflow-auto">
            <div v-if="extraInfo.info.callback && extraInfo.info.callbackonly">
              <pre>{{extraInfo.info.callback}}</pre>
            </div>
          </div>
          <div v-if="extraInfo.info.topup_id && !extraInfo.type">
              <div>
                <div class="row">
                <div class="col-lg-6">
                    <div class="card border custom-card">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14"> #{{  extraInfo.info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="bi bi-journal-text font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.contract_id }}: {{  extraInfo.info.contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border custom-card">
                    <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Sender's Bank/Crypto</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.sender_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account Name/Network</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.sender_account_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account No./Wallet Address</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.sender_account_number }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Select Recharge Currency</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.recharge_currency }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Currency Rate</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.currency_rate }}</h5>
                          </div>
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border custom-card">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Top Up ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.topup_id }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Top Up Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{ convertCurrencyFormat(extraInfo.info.topup_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.payin_total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.payin_rate }}% +
                              {{  extraInfo.info.contract_currency }} {{  extraInfo.info.payin_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Merchant Receives Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.merchant_received_amount,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2" v-if="account_type=='admin' && extraInfo.info.bank_account">
                            <div v-if="extraInfo.info.bank_account.account_db_code" class="mb-1">
                            <small class="font-size-13 mb-1">Company Bank Account</small>
                            <h5 class="font-size-15 mb-0">{{ extraInfo.info.bank_account.bank_name }}</h5>
                            <div class="text-body mb-1">{{ extraInfo.info.bank_account.bank_account_no }} ({{ extraInfo.info.bank_account.bank_account_name }}) </div>
                            </div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Company Receives Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.recharge_currency }} {{  convertCurrencyFormat(extraInfo.info.company_received_amount,true) }}</h5>
                          </div>
                          <!-- <div class="mb-2">
                            <small class="font-size-13">Payment Gateway</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.payment_gateway">{{ extraInfo.info.payment_gateway.payment_gateway_name || '-' }}</h5>
                          </div> -->
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID/UTR</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.transaction_id">{{ extraInfo.info.transaction_id || '-'}}</h5>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="extraInfo.info.transfer_receipts.length">
                              <span v-for="(v, i) in extraInfo.info.transfer_receipts" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="bi bi-receipt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="3" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_remarks" readonly v-if="extraInfo.info.transaction_remarks"></textarea>
                            <div class="text-muted" v-else>-</div>
                            
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'complete'">
                              <span class="badge bg-soft-success rounded-pill fw-medium font-size-14">Completed</span>
                            </h5>
                            
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'rejected'">
                              <span class="badge bg-soft-danger rounded-pill fw-medium font-size-14">Rejected</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'pending'">
                              <span class="badge bg-soft-warning rounded-pill fw-medium font-size-14">Pending</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reserved'">
                              <span class="badge bg-soft-info rounded-pill fw-medium font-size-14">Reserved</span>
                            </h5>

                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reversed'">
                              <span class="badge bg-soft-warning text-orange rounded-pill font-size-14">Reversed</span>
                            </h5>
                            
                            <textarea v-if="extraInfo.info.status == 'rejected'" rows="3" class="text-muted font-size-14 form-control" v-model="extraInfo.info.reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{   extraInfo.info.created_at }}</div>
                          <div class="font-size-12">Updated Date: {{   extraInfo.info.updated_at || '-' }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   extraInfo.info.processed_by || '-' }}</div>
                        </div>
                   </div>
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.deposit_id">
              <div>
                <div class="row">
                <div class="col-lg-6">
                    <div class="card border custom-card">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14 d-block mt-1"> #{{  extraInfo.info.merchant_info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="bi bi-journal-text font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.merchant_info.merchant_contract }}: {{  extraInfo.info.merchant_info.merchant_contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border custom-card">
                    <div class="card-body">
                          <div class="mb-2">
                            <small class="font-size-13">Method</small>
                            
                            <div v-if="extraInfo.info.method=='auto'">
                            <span class="badge bg-soft-primary font-size-14">Auto</span> 
                            </div>
                            <div v-else>
                              <span class="badge bg-soft-warning font-size-14" v-if="account_type=='admin'" v-b-tooltip.hover>Manual</span> 
                              <span class="badge bg-soft-warning font-size-14" v-else>Manual</span> 
                            </div>
                          </div>
                          <div class="mb-2" v-if="extraInfo.info.type">
                            <small class="font-size-13">Payment Type</small>
                            <div v-if="extraInfo.info.currency=='INR'">
                              <h5 class="font-size-15 mb-1" v-if="extraInfo.info.type=='payin'"> IMPS</h5>
                              <h5 class="font-size-15 mb-1" v-else-if="extraInfo.info.type=='ewallet'">UPI</h5>
                            </div>
                            <div v-else>
                              <h5 class="font-size-15 mb-1" v-if="extraInfo.info.type=='payin'"> Bank Transfer</h5>
                              <h5 class="font-size-15 mb-1" v-else-if="extraInfo.info.type=='ewallet'">E-Wallet</h5>
                            </div>
                          </div>
                          <div class="mb-0" v-if="account_type=='admin'">
                            <div class="mb-2" v-if="extraInfo.info.type">
                              <small class="font-size-13">Financial Group</small>
                              <div class="fw-medium text-dark mb-1" v-if="extraInfo.info.group_info">{{extraInfo.info.group_info.name}}
                              <span class="d-block font-size-13 text-primary">{{extraInfo.info.bank_account || '-'}}</span>
                              </div>
                              <div class="fw-medium text-dark mb-1" v-else>-</div>
                            </div>

                            <small class="font-size-13 d-block">Payment Method</small>
                            <div v-if="extraInfo.info.method=='auto'" class="mb-2">
                              <h5 class="font-size-15 mb-1">Cashier Page</h5>
                            </div>
                            <div v-else class="mb-2">
                              <h5 class="font-size-15 mb-1">Manually Top Up</h5>
                            </div>

                            <small class="font-size-13 d-block">Payment Link</small>
                            <div v-if="extraInfo.info.payment_link">
                              <h5 class="font-size-15 mb-1"><a  class="text-decoration-underline" :href="extraInfo.info.payment_link" title="Payment Link" target="_blank">Link</a></h5>
                            </div>
                            <div v-else>
                              <h5 class="font-size-15 mb-1 fw-normal">-</h5>
                            </div>
                            
                            
                            <div v-if="extraInfo.info.callback" class="mt-2">
                              <small class="font-size-13">Callback</small>
                              <div class="overlfow">
                                <pre>{{extraInfo.info.callback }}</pre>
                              </div>
                            </div>
                          </div>
                         
                          
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border custom-card">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Deposit ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.deposit_id }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Deposit Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.currency }} {{ convertCurrencyFormat(extraInfo.info.deposit_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.currency }} {{  convertCurrencyFormat(extraInfo.info.total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.charges_rate }}% + {{  extraInfo.info.currency }} {{  extraInfo.info.extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Nett Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.currency }} {{  convertCurrencyFormat(extraInfo.info.net_amount,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Reference No.</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.reference_no }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID/UTR</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.method=='auto'">{{ extraInfo.info.transaction_id || '-'}}</h5>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.platform_transaction_id || '-'}}</h5>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                     
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'reversed'">
                              <span class="badge bg-soft-warning text-orange rounded-pill font-size-14">Reversed</span>
                            </h5>
                              
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'success'">
                              <span class="badge bg-soft-success rounded-pill fw-medium font-size-14">Successful</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'pending'">
                              <span class="badge bg-soft-info rounded-pill fw-medium font-size-14">Processing</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'failed'">
                              <span class="badge bg-soft-danger rounded-pill fw-medium font-size-14">Failed</span>
                            </h5>
                            
                            <textarea v-if="extraInfo.info.status == 'failed'" rows="3" class="text-muted form-control" v-model="extraInfo.info.reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{   extraInfo.info.created_datetime }}</div>
                          <div class="font-size-12">Updated Date: {{   extraInfo.info.updated_datetime  || '-'}}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin' && extraInfo.info.processed_by">Processed By {{   extraInfo.info.processed_by }}</div>
                        </div>
                   </div>
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.payout_id && !extraInfo.type">
              <div>
                <div class="row">
                <div class="col-lg-6">
                  <div class="card border custom-card">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14"> #{{  extraInfo.info.merchant_info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="bi bi-journal-text font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.merchant_info.merchant_contract }}: {{  extraInfo.info.merchant_info.merchant_contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border custom-card">
                    <div class="card-body">
                          
                          <div class="mb-2">
                          <small class="font-size-13">Reference No.</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.reference_no }}</h5>
                          </div>
                          <div class="mb-2">
                          <small class="font-size-13">Merchant Item ID</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_item_id  || '-'}}</h5>
                          </div>
                          <!-- <div class="mb-2" v-if="account_type=='admin' && extraInfo.info.pg_info.pg_name">
                            <small class="font-size-13">Group / Method</small>
                            <h5 class="font-size-15 mb-1">  {{  extraInfo.info.pg_info.pg_name  || '-'}}</h5>
                            <div clas="my-1">
                              <button
                                class="btn btn-sm btn-outline-info"
                                @click="
                                  clickExtraInfo(
                                    `${extraInfo.info.pg_info.pg_name} - Callback Data`,
                                    extraInfo.info.pg_info
                                  )
                                "
                              >
                                Callback Data
                              </button>
                            </div>
                          </div>
                          <div v-else-if="account_type=='admin'" class="mb-2">
                            <small class="font-size-13">Payment Gateway</small>
                            <h5 class="font-size-15 mb-1">-</h5>
                          </div> -->
                          <div class="mb-2">
                          <small class="font-size-13">Callback Url</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_callback_url  || '-'}}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                          <small class="font-size-13">Receiving Bank</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.receiving_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Beneficiary Account Name</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_account_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Beneficiary Account No.</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_account_number }}</h5>
                          </div>
                          <div class="mb-2" v-if="extraInfo.info.extra_field_1.enabled">
                            <small class="font-size-13">{{ extraInfo.info.extra_field_1.name }}</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_1.value }}</h5>
                          </div>

                          <div class="mb-2" v-if="extraInfo.info.extra_field_2.enabled">
                            <small class="font-size-13">{{ extraInfo.info.extra_field_2.name }}</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_2.value }}</h5>
                          </div>

                          <div class="mb-2" v-if="extraInfo.info.extra_field_3.enabled">
                            <small class="font-size-13">{{ extraInfo.info.extra_field_3.name }}</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_3.value }}</h5>
                          </div>
                          <hr>
                          <div class="mb-3">
                            <small class="font-size-13 mb-1 d-block">Transaction Description</small>
                            <textarea rows="5" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_description" readonly></textarea>
                          </div>
                          
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border custom-card">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Payout ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.payout_id }}</h5>
                          </div>
                          <div class="mb-2">
                          <small class="font-size-13">Initiator</small>
                          <h5 class="font-size-15 mb-1">  <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ extraInfo.info.initiator }} </span></h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Payout Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{ convertCurrencyFormat(extraInfo.info.payout_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.payout_total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.payout_rate }}% +
                              {{  extraInfo.info.contract_currency }} {{  extraInfo.info.payout_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Amount Deducted</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.contract_currency }} {{  convertCurrencyFormat(extraInfo.info.amount_deducted,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2" v-if="account_type=='admin' && extraInfo.info.bank_account">
                            <div v-if="extraInfo.info.bank_account" class="mb-1">
                            <small class="font-size-13 mb-1">Company Bank Account</small>
                            <h5 class="font-size-15 mb-2">{{ extraInfo.info.bank_account.bank_name }}</h5>

                            <ul class="list-group shadow" :class="{'mb-3':extraInfo.info.bank_account.length}">
                              <li class="list-group-item p-2 d-flex justify-content-between align-items-center" v-for="(value,index) in extraInfo.info.bank_account" :key="index">
                                <div class="ms-2 me-auto text-muted font-size-13">
                                  <div class="fw-semibold text-dark">{{value.bank_name}}</div>
                                  {{value.bank_account_name}}<br>
                                  <span class="text-primary font-size-14">{{value.bank_account_no}}</span>
                                </div>
                                <span class="badge bg-soft-info text-info fw-medium rounded-pill lh-1 font-size-14">
                                  {{ convertCurrencyFormat(value.amount,true) }}
                                </span>
                              </li>
                            </ul>
                            <div v-if="!extraInfo.info.bank_account.length">-</div>


                            </div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.transaction_id">{{ extraInfo.info.transaction_id || '-'}}</h5>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="extraInfo.info.transaction_receipt.length">
                              <span v-for="(v, i) in extraInfo.info.transaction_receipt" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="bi bi-receipt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="4" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_remark" readonly v-if="extraInfo.info.transaction_remark"></textarea>
                            <div class="text-muted" v-else>-</div>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'complete'">
                              <span class="badge bg-soft-success rounded-pill fw-medium font-size-14">Completed</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'rejected'">
                              <span class="badge bg-soft-danger rounded-pill fw-medium font-size-14">Rejected</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'pending'">
                              <span class="badge bg-soft-warning rounded-pill fw-medium font-size-14">Pending</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reserved'">
                              <span class="badge bg-soft-info rounded-pill fw-medium font-size-14">Reserved</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reversed'">
                              <span class="badge bg-soft-warning text-orange rounded-pill fw-medium font-size-14">Reversed</span>
                            </h5>
                            <textarea v-if="extraInfo.info.status == 'rejected'" rows="3" class="text-muted form-control" v-model="extraInfo.info.reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{   extraInfo.info.created_at }}</div>
                          <div class="font-size-12">Updated Date: {{   extraInfo.info.updated_at || '-' }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   extraInfo.info.processed_by  || '-'}}</div>
                        </div>
                   </div>
                </div>
                <div class="col-12">  
                    
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.withdrawal_id && !extraInfo.type">
              <div>
                <div class="row">
                <div class="col-lg-6">
                    <div class="card border custom-card">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Reseller Detail</small>
                                      <small class="font-size-14 text-body"> {{  extraInfo.info.reseller_code }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.reseller_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="bi bi-journal-text font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.reseller_contract_id }}: {{  extraInfo.info.reseller_contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.reseller_contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border custom-card">
                    <div class="card-body">
                          
                          <div class="mb-2">
                          <small class="font-size-13">Receiving Bank/Crypto</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.receiver_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account Name/Network</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_bank_acc_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account No./Wallet Address</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_bank_acc }}</h5>
                          </div>

                          <div v-if="extraInfo.info.extra_field_1 && extraInfo.info.extra_field_2 && extraInfo.info.extra_field_3">
                            <div class="mb-2" v-if="extraInfo.info.extra_field_1.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_1.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_1.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="extraInfo.info.extra_field_2.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_2.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_2.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="extraInfo.info.extra_field_3.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_3.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_3.value }}</h5>
                            </div>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Description</small>
                            <textarea rows="5" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_description" readonly></textarea>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Select to Withdrawal Currency</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.settlement_currency }}</h5>
                          </div>
                          <div class="mb-2" v-if="account_type=='admin' && extraInfo.info.bank_account">
                            <div v-if="extraInfo.info.bank_account.account_db_code" class="mb-1">
                            <small class="font-size-13 mb-1">Company Bank Account</small>
                            <h5 class="font-size-15 mb-0">{{ extraInfo.info.bank_account.bank_name }}</h5>
                            <div class="text-body mb-1">{{ extraInfo.info.bank_account.bank_account_no }} ({{ extraInfo.info.bank_account.bank_account_name }}) </div>
                            </div>
                          </div>
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border custom-card">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Withdrawal ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.withdrawal_id }}</h5>
                          </div>

                          <div class="mb-2">
                          <small class="font-size-13">Initiator</small>
                          <h5 class="font-size-15 mb-1">  <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ extraInfo.info.initiator }} </span></h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Withdrawal Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.reseller_contract_currency }} {{ convertCurrencyFormat(extraInfo.info.withdraw_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.reseller_contract_currency }} {{  convertCurrencyFormat(extraInfo.info.total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.reseller_contract_rate }}% +
                              {{  extraInfo.info.reseller_contract_currency }} {{  extraInfo.info.reseller_contract_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Amount after Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.reseller_contract_currency }} {{  convertCurrencyFormat(extraInfo.info.total_withdrawal_after_charge,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Transfer Rate</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.transfer_rate || '-' }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transfer Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.settlement_currency }} {{  convertCurrencyFormat(extraInfo.info.transfer_amount || 0,true) }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.transaction_id">{{ extraInfo.info.transaction_id || '-'}}</h5>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="3" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_remark" readonly v-if="extraInfo.info.transaction_remark"></textarea>
                            <div class="text-muted" v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="extraInfo.info.bank_transaction_slip.length">
                              <span v-for="(v, i) in extraInfo.info.bank_transaction_slip" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="bi bi-receipt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'complete'">
                              <span class="badge bg-soft-success rounded-pill fw-medium font-size-14">Completed</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'rejected'">
                              <span class="badge bg-soft-danger rounded-pill fw-medium font-size-14">Rejected</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'pending'">
                              <span class="badge bg-soft-warning rounded-pill fw-medium font-size-14">Pending</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reserved'">
                              <span class="badge bg-soft-info rounded-pill fw-medium font-size-14">Reserved</span>
                            </h5>
                            <textarea v-if="extraInfo.info.status == 'rejected'" rows="3" class="text-muted font-size-14 form-control" v-model="extraInfo.info.rejected_reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{   extraInfo.info.created_at }}</div>
                          <div class="font-size-12">Updated Date: {{   extraInfo.info.updated_date || '-' }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   extraInfo.info.processed_by  || '-'}}</div>
                        </div>
                   </div>
                </div>
                <div class="col-12">  
                    
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.settlement_id && !extraInfo.type">
              <div>
                <div class="row">
                <div class="col-lg-6">
                    <div class="card border custom-card">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14"> #{{  extraInfo.info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="bi bi-journal-text font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  extraInfo.info.merchant_contract_id }}: {{  extraInfo.info.merchant_contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  extraInfo.info.merchant_contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border custom-card">
                    <div class="card-body">
                      <div class="mb-2">
                          <small class="font-size-13">Callback Url</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.merchant_callback_url  || '-'}}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                          <small class="font-size-13">Receiving Bank/Crypto</small>
                          <h5 class="font-size-15 mb-1">  {{  extraInfo.info.receiver_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account Name/Network</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_bank_acc_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Account No./Wallet Address</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.receiver_bank_acc }}</h5>
                          </div>

                          <div v-if="extraInfo.info.extra_field_1 && extraInfo.info.extra_field_2 && extraInfo.info.extra_field_3">
                            <div class="mb-2" v-if="extraInfo.info.extra_field_1.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_1.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_1.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="extraInfo.info.extra_field_2.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_2.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_2.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="extraInfo.info.extra_field_3.enabled">
                              <small class="font-size-13">{{ extraInfo.info.extra_field_3.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ extraInfo.info.extra_field_3.value }}</h5>
                            </div>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Description</small>
                            <textarea rows="5" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_description" readonly></textarea>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Select to Withdrawal Currency</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.settlement_currency }}</h5>
                          </div>
                          <div class="mb-2" v-if="account_type=='admin' && extraInfo.info.bank_account">
                            <div v-if="extraInfo.info.bank_account.account_db_code" class="mb-1">
                            <small class="font-size-13 mb-1">Company Bank Account</small>
                            <h5 class="font-size-15 mb-0">{{ extraInfo.info.bank_account.bank_name }}</h5>
                            <div class="text-body mb-1">{{ extraInfo.info.bank_account.bank_account_no }} ({{ extraInfo.info.bank_account.bank_account_name }}) </div>
                            </div>
                          </div>
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border custom-card">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Settlement ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ extraInfo.info.settlement_id }}</h5>
                          </div>

                          <div class="mb-2">
                          <small class="font-size-13">Initiator</small>
                          <h5 class="font-size-15 mb-1">  <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ extraInfo.info.initiator }} </span></h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Withdrawal Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.merchant_contract_currency }} {{ convertCurrencyFormat(extraInfo.info.withdraw_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.merchant_contract_currency }} {{  convertCurrencyFormat(extraInfo.info.total_charges,true) }} <small class="fw-normal text-muted">({{  extraInfo.info.merchant_contract_rate }}% +
                              {{  extraInfo.info.merchant_contract_currency }} {{  extraInfo.info.merchant_contract_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Amount after Charges</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.merchant_contract_currency }} {{  convertCurrencyFormat(extraInfo.info.total_withdrawal_after_charge,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Transfer Rate</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.transfer_rate || '-' }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transfer Amount</small>
                            <h5 class="font-size-15 mb-1">{{ extraInfo.info.settlement_currency }} {{  convertCurrencyFormat(extraInfo.info.transfer_amount || 0,true) }}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID</small>
                            <h5 class="font-size-15 mb-1" v-if="extraInfo.info.transaction_id">{{ extraInfo.info.transaction_id || '-'}}</h5>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="3" v-if="extraInfo.info.transaction_remark" class="form-control font-size-13 text-muted w-100" v-model="extraInfo.info.transaction_remark" readonly></textarea>
                            <div class="text-muted" v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="extraInfo.info.bank_transaction_slip.length">
                              <span v-for="(v, i) in extraInfo.info.bank_transaction_slip" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="bi bi-receipt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="extraInfo.info.status == 'complete'">
                              <span class="badge bg-soft-success rounded-pill fw-medium font-size-14">Completed</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'rejected'">
                              <span class="badge bg-soft-danger rounded-pill fw-medium font-size-14">Rejected</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'pending'">
                              <span class="badge bg-soft-warning rounded-pill fw-medium font-size-14">Pending</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="extraInfo.info.status == 'reserved'">
                              <span class="badge bg-soft-info rounded-pill fw-medium font-size-14">Reserved</span>
                            </h5>
                            <textarea v-if="extraInfo.info.status == 'rejected'" rows="3" class="text-muted font-size-14 form-control" v-model="extraInfo.info.rejected_reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{   extraInfo.info.created_at }}</div>
                          <div class="font-size-12">Updated Date: {{   extraInfo.info.updated_date }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   extraInfo.info.processed_by }}</div>
                        </div>
                   </div>
                </div>
                <div class="col-12">  
                    
                </div>
              
              </div>
            </div>
          </div>
          <div v-if="extraInfo.info.transaction_remark && extraInfo.type"> {{ extraInfo.info.transaction_remark}}</div>
          <div v-if="extraInfo.info.transaction_remarks && extraInfo.type"> {{ extraInfo.info.transaction_remarks}}</div>
          <div v-if="extraInfo.info.transfer_remarks"> {{ extraInfo.info.transfer_remarks}}</div>
          <div v-if="(extraInfo.info.merchant_db_code && !extraInfo.type) || (extraInfo.info.bank_account && !extraInfo.type)">
              <div v-if="extraInfo.info.hour_history">
                <div class="row">
                  <div class="col-12 d-none">
                    <div>
                      <label class="d-inline-flex align-items-center">
                        Filter:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Keyword..."
                          class="form-control ms-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                      <div class="table-responsive mb-0">
                        <!-- striped -->
                          <b-table
                            class="text-nowrap align-middle mb-0 custom-datatable-table"
                            :items="extraInfo.info.hour_history"
                            striped
                            :fields="visibleFields()"
                            responsive="sm"
                            :per-page="perPage2"
                            :current-page="currentPage2"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            :busy="loading"
                            show-empty
                          >        
                          <template>
                            <div class="text-center py-5 text-center text-muted" slot="table-busy">
                            <div
                                class="spinner-border text-secondary my-2"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                              <br />
                              <div>Loading...</div>
                            </div>
                          </template>
                          <template slot="empty">
                            <div class="text-center text-muted py-5">
                              <i class="bi bi-bank me-1"></i> No Data Available
                            </div>
                          </template>
                          <template slot="emptyfiltered">
                            <div class="text-center text-muted py-5">
                              <i class="bi bi-bank me-1"></i> There are no records matching your keyword
                            </div>
                          </template>
                          <!-- <template #cell(index)="data">
                            {{ data.index + 1 }}
                            Whole Index
                            {{ currentPage2 * perPage2 - perPage2 + data.index }}
                          </template>

                          <template #cell(name)="data">
                            {{data.value}}
                          </template> -->
                          
                          <template #cell(success_amount)="data">
                            <div class="text-success fw-medium" v-if="parseFloat(data.item.success_amount)>0">
                              {{ convertCurrencyFormat(data.item.success_amount,true)}} 
                            </div>
                            <div v-else> {{ convertCurrencyFormat(data.item.success_amount,false)}} </div>
                          </template>

                          <template #cell(success_count)="data">
                            <div class="text-success fw-medium" v-if="parseFloat(data.item.success_count)>0">
                              {{ convertCurrencyFormat(data.item.success_count,false)}} 
                            </div>
                            <div v-else> {{ convertCurrencyFormat(data.item.success_count,false)}} </div>
                          </template>

                          <template #cell(failed_count)="data">
                            <div class="text-success" v-if="parseFloat(data.item.failed_count)>0">
                              {{ convertCurrencyFormat(data.item.failed_count,false)}} 
                            </div>
                            <div v-else> {{ convertCurrencyFormat(data.item.failed_count,false)}} </div>
                          </template>

                          <template #cell(success_rate)="data">
                            <div class="text-success" v-if="parseFloat(data.item.success_rate)>50">
                              <div class="block w-100 bg-soft-success badge py-1 font-size-14 fw-medium">
                                {{ convertCurrencyFormat(data.item.success_rate || 0,true) }}%
                              </div>
                            </div>
                            <div  class="text-danger" v-else-if="parseFloat(data.item.success_rate)>0">
                              <div class="block w-100 bg-soft-danger badge py-1 font-size-14 fw-medium">
                                {{ convertCurrencyFormat(data.item.success_rate || 0,true) }}%
                              </div>
                            </div>
                            <div v-else>
                              <div class="block w-100 bg-soft-secondary badge py-1 font-size-14 fw-medium">
                                - %
                              </div>
                            </div>
                          </template>

                         
                        </b-table>
                      </div>
                      
                  </div>
                  <div class="col-12 d-none" v-if="extraInfo.info.hour_history && !loading">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-end"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage2"
                            :total-rows="totalRows"
                            :per-page="perPage2"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- datatable end -->
                </div>
            </div>
          </div>
          <div class="d-grid gap-2 mt-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-extra-info'].hide()">Cancel</button>
          </div>
        </div>
      </div>
    </b-modal>
    <Common ref="commonFunc" />
    <extraInfo2 ref="modalFunc3" :data="{ 'extraInfo': currentClickedInfo}"/>
  </div>
</template>
<script>
  import Common from "@/components/common";
  import extraInfo2 from "@/views/pages/app/modal/extraInfo2";
  export default {
    components: {
      Common,
      extraInfo2
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        extraInfo:{title: "", info: {}, type:""},
        currentClickedInfo:{title: "", info: {}},
        account_type:"",
        loading:false,
        create_modify_permission:false,
        //datatable
        totalRows: 1,
        currentPage2: 1,
        perPage2: 50,
        filter: "",
        filterOn: [],
        sortBy: "time",
        sortDesc: false,
        fields: [
          {
            key: "time",
            label:"Time",
            thClass:"user-select-none",
            tdClass: "align-middle text-center",
            sortable: true,
          },
          {
            key: "success_amount",
            label:"Total Success Amount",
            thClass:"user-select-none",
            tdClass: "align-middle text-end",
            sortable: true,
            formatter: (value) => {
              if (value) {
                return this.convertCurrencyFormat(value,true);
              } else {
                return 0; // or any default value if upi_accounts is not an array
              }
            },
          },
          {
            key: "success_count",
            label:"Success Trx.",
            thClass:"user-select-none",
            tdClass: "align-middle text-end",
            sortable: true,
          },
          {
            key: "failed_count",
            label:"Failed Trx.",
            thClass:"user-select-none",
            tdClass: "align-middle text-end",
            sortable: true,
          },
          {
            key: "success_rate",
            label:"Success Rate",
            thClass:"user-select-none",
            tdClass: "align-middle text-center",
            sortable: true,
          }
        ]
      };
    },
    middleware: "authentication",
    async mounted() {
      await this.reload();
    },
    created() {},
    methods: {
      visibleFields() {
        return this.fields.filter(field => {
        // Include all fields except the "Action" column if the condition is true
        return field.key !== 'action' || this.create_modify_permission;
      });
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage2 = 1;
      },
      clickExtraInfo(title, object){
        this.currentClickedInfo = {title: '', info: {}}
        this.currentClickedInfo = {title: title, info: object}
        setTimeout(() => this.$refs.modalFunc3.showExtraInfoModal(), 0)
      },
      showExtraInfoModal() {
        this.$refs['modal-extra-info'].show()
        this.extraInfo = this.data.extraInfo;
      },
      convertCurrencyFormat(value, show00) {
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
        }
      },
      resetModal() {
        this.extraInfo = {title: "", info: {}}
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            console.log(data)
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      }
    }
  };
</script>